/**
 * Code provided by https://simplycalc.com/luhn-validate.php under public domain.
 */


/* Implement the Luhn algorithm to calculate the Luhn check digit.
 * Return the check digit.
 */
const luhnChecksum = (code) => {
  var len = code.length
  var parity = len % 2
  var sum = 0
  for (var i = len-1; i >= 0; i--) {
    var d = parseInt(code.charAt(i))
    if (i % 2 == parity) { d *= 2 }
    if (d > 9) { d -= 9 }
    sum += d
  }
  return sum % 10
};

/* Return a full code (including check digit), from the specified partial code (without check digit). */
const luhnCalculate = (partcode) => {
  var checksum = luhnChecksum(partcode + "0");
  return checksum == 0 ? 0 : 10 - checksum;
};

/* Return true if specified code (with check digit) is valid. */
const luhnValidate = (fullcode) => {
  return luhnChecksum(fullcode) == 0
};

/* Return true if checksum for the provided number (except the initial two digits) is valid.
 * This is useful for validating the checksum of a personal ID number whith four digits for the year.
*/
const validPersonalIdChecksum = (value) => {
  return value && luhnValidate(value.substring(2));
};

export {
  luhnCalculate,
  luhnValidate,
  validPersonalIdChecksum
}
